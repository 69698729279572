import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_21 from '../../assets/icons/common/RG_21.svg';
import { Container, Text, Line } from './ResponsibleGamingStyles';
import { useTheme } from 'styled-components';
import { AppName } from 'Types';

export const Space = ({ children }: { children: React.ReactElement }) => (
  <> {children} </>
);

export const ResponsibleGamingCasino = () => {
  const theme = useTheme();
  const isMoheganSun = theme.name === AppName.MOHEGAN_SUN_CASINO;

  return (
    <Container>
      <ReactSVG src={rg_21} />
      <Text>
        <Line>Gambling Problem? Call 1-800-GAMBLER or</Line>
        <Line>
          visit
          <Space>
            <Link
              href="https://www.fanduel.com/rg"
              target="_blank"
              children={<>FanDuel.com/RG</>}
              fontSize={14}
            />
          </Space>
          (MI, NJ, PA{isMoheganSun && ', CT'});
        </Line>
        <Line>
          or visit
          <Space>
            <Link
              href="https://www.1800gambler.net/"
              target="_blank"
              children={<>www.1800gambler.net</>}
              fontSize={14}
            />
          </Space>
          (WV).
        </Line>
      </Text>
    </Container>
  );
};
