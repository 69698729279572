import * as amplitude from '@fanduel/core-analytics';
import { useParams } from 'react-router-dom';
import { MS_STATE } from '../../constants';
export const useJoinUrl = () => {
  const { code = '' } = useParams();

  const buildUrls = (state?: string, isMoheganSun?: boolean) => {
    const ampSessionId = amplitude.getSessionId();
    const ampDeviceId = amplitude.getDeviceId();
    const baseUrl = process.env.JOIN_URL;
    const ctBaseUrl = process.env.CT_JOIN_URL;
    const fdCasinoHomeUrl = process.env.FD_CASINO_HOME_URL;
    const homeURL = process.env.HOME_URL;

    const externalReferrerUrl =
      isMoheganSun && state !== MS_STATE.abbreviation
        ? fdCasinoHomeUrl
        : homeURL;
    const urlParams = `?raf=${code}&external-referrer=${externalReferrerUrl}&ampSessionId=${ampSessionId}&ampDeviceId=${ampDeviceId}`;

    const defaultUrl = `${baseUrl}${urlParams}`;
    const connecticutUrl = `${ctBaseUrl}${urlParams}`;

    switch (state) {
      case 'CT':
        return connecticutUrl;
      case undefined:
        return defaultUrl;
      default:
        return defaultUrl.replace('%state%', state.toLowerCase());
    }
  };

  return { buildUrls };
};
