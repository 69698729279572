import { ReactSVG } from 'react-svg';
import styled, { useTheme } from 'styled-components';
import { useCopy } from '../../contexts/CopyContext';
import { RewardType } from 'Types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
`;

const Text = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.headerText};
  font-weight: Bold;
  padding-top: 20px;
`;

const Message = styled.div`
  ${({ theme }) => `
        color: ${theme.colors.defaultText};
        font-size: ${theme.defaultText.text.size};
        font-weight: ${theme.defaultText.text.weight};
    `}
  text-align: center;
  padding-top: 20px;
`;

type NoReferralsProps = {
  rewardType?: RewardType;
};

export const NoReferrals = ({ rewardType }: NoReferralsProps) => {
  const theme = useTheme();
  const { friendsWillAppearHereMessage, noReferralMessage } = useCopy();

  const referrtoEarn =
    rewardType === 'BONUS_SPINS_REWARD' ? 'Bonus Spins' : '$$$';

  return (
    <Container>
      <ReactSVG
        data-testid={theme.icons.noReferral}
        src={theme.icons.noReferral}
      />
      <Text>No referrals yet</Text>
      <Message>
        {friendsWillAppearHereMessage}
        {noReferralMessage && ` (${noReferralMessage})`}
      </Message>
      <Message>{`Start referring friends to earn ${referrtoEarn}!`}</Message>
    </Container>
  );
};
