import TagManager from 'react-gtm-module';
import { GtmProps } from '../types/gtmTypes';
import { getProductName } from '../utils/appService';

export const tagScreenLoad = (props: GtmProps) => {
  const { jurisdiction, path, fdAccountId, siteVersion, loginStatus } = props;
  const gtmArgs = {
    dataLayer: {
      event: 'screen_load',
      jurisdiction: jurisdiction,
      product: 'refer_a_friend',
      brand: 'fanduel_' + getProductName(PRODUCT),
      pagePath: path,
      fdAccountId: fdAccountId,
      siteVersion: siteVersion,
      loginStatus: loginStatus,
    },
  };
  TagManager.dataLayer(gtmArgs);
};
