import styled from 'styled-components';

import { Link } from '../Link';

const StyledTnC = styled.div`
  display: flex;
  flex-direction: column;
`;

const DefaultText = styled.span`
  ${({ theme }) => `
        font-weight: ${theme.defaultText.text.weight};
        font-size: ${theme.defaultText.text.size};
        color: ${theme.colors.topSectionText};
    `}
`;

const LinkWrapper = styled.span`
  color: ${({ theme }) => theme.colors.termsAndConditionsText};
  font-weight: ${({ theme }) => theme.defaultText.text.boldText};
  text-decoration: ${({ theme }) => theme.link.text.textDecoration};
`;

export type Props = {
  tncUrl: string;
};

export const TermsAndConditions = (props: Props) => {
  return (
    <StyledTnC>
      <DefaultText>Max of 5 friends every 30 days!</DefaultText>
      <Link
        href={props.tncUrl}
        children={<LinkWrapper>Terms and conditions apply.</LinkWrapper>}
        target="_blank"
        fontSize={14}
      />
    </StyledTnC>
  );
};
