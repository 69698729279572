import { DefaultTheme } from 'styled-components';
import Alert from '../assets/icons/cas/Alert.svg';
import Cards from '../assets/icons/cas/Cards.svg';
import BonusSpins from '../assets/icons/cas/BonusSpins.svg';
import ShieldBonusSpins from '../assets/icons/cas/ShieldLandingPage.svg';
import CasinoLogo from '../assets/icons/cas/CasinoLogo.svg';
import Dealer from '../assets/icons/cas/Dealer.svg';
import People from '../assets/icons/cas/People.svg';
import { casinoText } from './casinoText';
import {
  black,
  casinoBlue,
  casinoPurple,
  fanduelBlue,
  fanduelBlueD1,
  fanduelGrey,
  fanduelGreyD3,
  fanduelGreyD4,
  fanduelGreyL2,
  fanduelGreyL3,
  fanduelGreyL4,
  fanduelGreyL5,
  fanduelNavy,
  fanduelRedD2,
  fanduelWhite,
} from './colors';
import { AppName } from '../types/AppName';

export const casino: DefaultTheme = {
  name: AppName.CASINO,
  fontFamily: "'ProximaNova', Arial",
  colors: {
    pageBg: fanduelGreyL4,
    contentBg: fanduelWhite,
    containerBg: fanduelGreyL5,
    headerBg: fanduelNavy,
    containerBorder: fanduelGreyL2,
    inviteBorder: fanduelBlueD1,
    headerText: fanduelNavy,
    defaultText: fanduelGreyD4,
    progressText: fanduelGreyD3,
    buttonText: fanduelWhite,
    topSectionText: fanduelWhite,
    linkText: fanduelBlueD1,
    buttonGradient: fanduelBlue,
    successTextDark: casinoBlue,
    successTextLight: casinoPurple,
    successIcon: casinoBlue,
    successIconDark: casinoBlue,
    successIconLight: casinoPurple,
    warningText: fanduelRedD2,
    neutralLine: fanduelGrey,
    topSectionThemeBg:
      'linear-gradient(338.22deg, #005FC8 59.08%, #61019B 100%, #61019B 100%)',
    termsAndConditionsText: fanduelWhite,
    headerLogoBg: 'linear-gradient(338.22deg, #005FC8 41.8%, #61019B 100%)',
    joinFanduelBtn: `linear-gradient(
            180deg,
            ${fanduelBlue} 0%,
            ${fanduelBlueD1} 100%
            );`,
    stateSelectorDetail: fanduelGreyL2,
    stateSelectorArrow: black,
    disabledButton: fanduelGreyL3,
    disabledButtonText: fanduelGreyD3,
  },
  mediaQueries: {
    extraLarge: {
      maxWidth: '1024px',
    },
    large: {
      maxWidth: '960px',
    },
    medium: {
      maxWidth: '640px',
    },
    regular: {
      maxWidth: '430px',
    },
    small: {
      maxWidth: '375px',
    },
    extraSmall: {
      maxWidth: '320px',
    },
  },
  defaultText: {
    text: {
      size: '14px',
      weight: 'normal',
      boldText: 'bold',
      extraSmallSize: '',
      textDecoration: '',
      whiteSpaceSmall: 'normal',
      whiteSpaceLarge: 'normal',
    },
  },
  icons: {
    main: Cards,
    noReferral: Dealer,
    error: Alert,
    leftDots: '',
    logo: CasinoLogo,
    rightDots: '',
    landingGraphic: People,
    bonusSpins: BonusSpins,
    landingBonusSpins: ShieldBonusSpins,
  },
  text: casinoText,
  header: {
    text: {
      size: '20px',
      weight: 'bold',
      extraSmallSize: '',
      boldText: 'normal',
      textDecoration: '',
      whiteSpaceSmall: 'normal',
      whiteSpaceLarge: 'normal',
    },
  },
  subTitle: {
    text: {
      size: '16px',
      weight: 'normal',
      extraSmallSize: '0.85rem',
      boldText: 'normal',
      textDecoration: '',
      whiteSpaceSmall: 'normal',
      whiteSpaceLarge: 'pre-wrap',
    },
  },
  welcome: {
    text: {
      size: '36px',
      weight: 'normal',
      extraSmallSize: '2rem',
      boldText: 'normal',
      textDecoration: '',
      whiteSpaceSmall: 'normal',
      whiteSpaceLarge: 'normal',
    },
  },
  link: {
    text: {
      size: '14px',
      weight: 'normal',
      extraSmallSize: '',
      boldText: 'normal',
      textDecoration: 'underline solid #d3d3d3 0.005px',
      whiteSpaceSmall: 'normal',
      whiteSpaceLarge: 'normal',
    },
  },
  borderRadius: {
    horizontalPadding: {
      radius: '4px',
    },
  },
  overflow: {
    imageDiv: {
      isHidden: 'auto',
    },
    home: {
      isHidden: 'auto',
    },
  },
  margins: {
    topSection: {
      row: {
        margin: '0 0 76px',
        medium: '50px',
      },
      rowSmall: {
        margin: '0 0 -20px',
        medium: '',
      },
      column: {
        margin: '0 -30px 36px 0',
        medium: '36px',
      },
      outer: {
        margin: '0',
        medium: '0',
      },
      imageDiv: {
        small: {
          margin: '0 -60px 0 0',
          medium: '',
        },
        extraSmall: {
          margin: '0 -80px 0 0',
          medium: '',
        },
        medium: {
          margin: '0 -50px 0 0',
          medium: '',
        },
        maxWidth: '',
        maxHeight: '',
      },
      styledSubTitle: {
        margin: '0 50px 0 0',
        medium: '0',
      },
      topMarginSpacing: {
        extraSmall: '8px',
        small: '16px',
        medium: '25px',
        large: '-28px',
        extraLarge: '20px',
        maxSpacing: '28px',
      },
    },
    headerSection: '15px 0 5px 0',
    landingPage: 16,
  },
  paddings: {
    column: {
      large: '0 0 0 16px',
      small: '0',
    },
    horizontalPadding: {
      large: '16px',
      small: '0',
    },
    errorPagePadding: {
      large: '10% 0',
      small: '5% 0',
    },
  },
  scale: {
    medium: '0.7',
    small: '0.588',
    extraSmall: '0.45',
  },
  dimensions: {
    width: '',
    subtitleMaxWidth: '250px',
    errorPageHeight: 'auto',
  },
  position: '25px',
  positionType: 'relative',
  positionTop: '-40px',
};
