import styled from 'styled-components';

export const LandingStyle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                padding-left: 16px;
                padding-right: 16px;
            }`
        }
    `}
`;

export const Graphic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SvgWrapper = styled.div`
  width: ${({ theme }) => theme.dimensions.width};
`;

export const TopMargin = styled.div`
  margin-top: ${(props: { value: number }) => props.value}px;
`;
