import { Subtitle } from '../Subtitle';
import { MaxReferralsReached } from './MaxReferralsReached';

type MaxReferralsProps = {
  isMaxReferralsReached: boolean;
};

export const MaxReferrals = (props: MaxReferralsProps) => {
  return props.isMaxReferralsReached ? <MaxReferralsReached /> : <Subtitle />;
};
