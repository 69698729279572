const errorPageContent = {
  headerText: "Something went wrong",
  message: "We're looking into it. Try again later",
  buttonText: "Visit FanDuel Casino",
};

export const casinoText = {
  maxHeader: "You're on a hot streak!",
  errorPage: {
    referralCenter: errorPageContent,
    landingPage: errorPageContent,
  },
};
