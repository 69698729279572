import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_19 from '../../assets/icons/common/RG_19.svg';
import { Container, Text, Line } from './ResponsibleGamingStyles';

export const StartSpace = ({ children }: { children: React.ReactElement }) => (
  <> {children}</>
);

export const ResponsibleGamingCanada = () => (
  <Container>
    <ReactSVG src={rg_19} />
    <Text>
      <Line>By creating an account you are agreeing to </Line>
      <Line>FanDuel Group's Terms of Use </Line>
      <Line>and Privacy Policy and to be updated about </Line>
      <Line>FanDuel Group products, news, and promotions.</Line>
      <Line>Users must be 19+ to play Casino and </Line>
      <Line>to place bets on Sportsbook.</Line>
      <Line>If you or someone you know has a gambling problem</Line>

      <Line>and wants help, call 1-866-531-2600 or </Line>
      <Line>
        visit
        <StartSpace>
          <Link
            href="https://www.connexontario.ca/en-ca/"
            target="_blank"
            children={<>Connex Ontario</>}
            fontSize={14}
          />
        </StartSpace>
        .
      </Line>
    </Text>
  </Container>
);
