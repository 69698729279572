import {
  CppPromotionInfo,
  OriginatingProduct,
  ReferralOfferDataList,
  RewardSplit,
  RewardType,
} from '../../lib/types';

export const determineRewardSplitType = (
  sbkReferrerRewardAmount: number,
  sbkRefereeRewardAmount: number,
  casinoReferrerRewardAmount: number,
  casinoRefereeRewardAmount: number,
  rewardType: RewardType
): RewardSplit => {
  switch (rewardType) {
    case 'EXTRA_WALLET_REWARD':
      if (sbkRefereeRewardAmount === 0) return 'zeroRecruit';
      if (sbkReferrerRewardAmount !== sbkRefereeRewardAmount)
        return 'asymmetric';
      break;
    case 'BONUS_AWARDING_REWARD':
      if (casinoRefereeRewardAmount === 0) return 'zeroRecruit';
      if (casinoReferrerRewardAmount !== casinoRefereeRewardAmount)
        return 'asymmetric';
      break;
    case 'PRICE_BOOST_REWARD':
      if (sbkRefereeRewardAmount === 0) return 'zeroRecruit';
      break;
    case 'BONUS_SPINS_REWARD':
      if (casinoRefereeRewardAmount === 0) return 'zeroRecruit';
      break;
  }

  return 'symmetric';
};

export const getCppOfferData = (
  cppPromotionInfo: CppPromotionInfo[],
  product: OriginatingProduct
) => cppPromotionInfo?.find((offer) => offer?.product === product);

export const getReferralOfferData = (
  referralOfferDataList: ReferralOfferDataList[],
  product: OriginatingProduct
) => referralOfferDataList?.find((offer) => offer?.product === product);
