import { Subtitle } from '../Subtitle';
import { useCopy } from '../../contexts/CopyContext';

type HeaderTextSubtitle = { isAsymmetric: boolean };

export const HeaderTextSubtitle = ({ isAsymmetric }: HeaderTextSubtitle) => {
  const copy = useCopy();

  return copy.headerTextSubtitle ? (
    <Subtitle
      centered={false}
      text={copy.headerTextSubtitle}
      fontSize={isAsymmetric ? '20px' : undefined}
    ></Subtitle>
  ) : null;
};
