import styled from 'styled-components';

export const Container = styled.div<{ isZeroRecruitCasino: boolean }>`
  ${({ theme }) => `
        background-color: ${theme.colors.contentBg};
        border: 1px solid ${theme.colors.containerBorder};
        color: ${theme.colors.defaultText};
    `}
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  text-align: center;
  padding: ${(props) => `${props.isZeroRecruitCasino ? '9px 16px 16px' : '16px'}`};
  margin: 16px 0px;
  max-width: 343px;
  ${({ theme }) => `
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                margin-left: 26px;
                margin-right: 26px;
            }`
        }
    `}
`;

export const TextSection = styled.div`
  font-size: 18px;
  line-height: 125%;
  ${({ theme }) => `
        color: ${theme.colors.headerText};
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                font-size: 16px;
            }`
        }
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: 14px;
            }`
        }
    `}
`;

export const Text = styled.p`
  padding-bottom: ${(props: { padding?: number }) => props.padding ?? 16}px;
  white-space: pre-wrap;
`;

export const Button = styled.a<{ disabled: boolean }>`
  ${({ theme }) => `
        background: ${theme.colors.joinFanduelBtn};
        color: ${theme.colors.buttonText};
        
    `}
  border-radius: 4px;
  width: 100%;
  height: 44px;
  text-align: center;
  padding: 13.5px 16px;
  text-decoration: none;
  ${(props) =>
    props.disabled &&
    `
            background: ${props.theme.colors.disabledButton};
            color: ${props.theme.colors.disabledButtonText};
            &:hover {
                cursor: default;
            }
    `}
`;

export const Terms = styled.div`
  font-size: 10px;
  line-height: 125%;
  color: ${({ theme }) => theme.colors.defaultText};
  padding-top: 16px;
`;

export const LinkWrapper = styled.span`
  padding: 22px 0px 2px 0px;
`;
