import { useTheme } from 'styled-components';
import { OfferType, RewardType } from '../../types/OfferModel';
import { Link } from '../Link';
import { Card, CardHeader, CardTitle, TnCContainer } from './HowItWorksStyles';
import { Step } from './Step';
import {
  casBonusAsymmetricStep,
  casBonusSpinsStep,
  casBonusSpinsZeroRecruitStep,
  casBonusStep,
  casBonusZeroRecruitStep,
  casWagerStep,
  HowItWorksStep,
  joinStep,
  msBonusStep,
  msWagerStep,
  RewardSpecificHowItWorksStep,
  sbkBonusAsymmetricStep,
  sbkBonusStep,
  sbkBonusZeroRecruitStep,
  sbkProfitBoostTokenStep,
  sbkProfitBoostTokenZeroRecruitStep,
  sbkWagerStep,
} from './StepsData';
import { determineRewardSplitType } from '../../../app/utils/commonFunctions';
import { AppName } from 'Types';

export type StepProps = {
  label: string;
  description: string;
  icon: string;
  color: string;
  terms?: string;
};

export type HowItWorksProps = {
  sbkReferrerRewardAmount: number;
  sbkRefereeRewardAmount: number;
  sbkWagerAmount: number;
  casinoReferrerRewardAmount: number;
  casinoRefereeRewardAmount: number;
  casinoWagerAmount: number;
  offerType: OfferType;
  tncUrl: string;
  offerWindowDays: number;
  rewardType: RewardType;
  referrerPercentage: number;
};

type CasinoSteps = {
  casBonusStep: HowItWorksStep;
  casWagerStep: HowItWorksStep;
  casBonusAsymmetricStep: HowItWorksStep;
  casBonusZeroRecruitStep: HowItWorksStep;
  casBonusSpinsStep: HowItWorksStep;
  casBonusSpinsZeroRecruitStep: HowItWorksStep;
};

export const HowItWorks = ({
  sbkReferrerRewardAmount = 0,
  sbkRefereeRewardAmount = 0,
  sbkWagerAmount,
  casinoReferrerRewardAmount = 0,
  casinoRefereeRewardAmount = 0,
  casinoWagerAmount,
  offerType,
  tncUrl,
  offerWindowDays,
  rewardType,
  referrerPercentage,
}: HowItWorksProps) => {
  const theme = useTheme();
  const { name: themeName } = theme;
  const hybrid = offerType === 'HYBRID';
  const productSpecificSteps = getProductSpecificSteps();

  const arrangedSteps = productSpecificSteps.reduce(
    (array: HowItWorksStep[][], step) => {
      (step.appName === themeName ? array[0] : array[1]).push(step);
      return array;
    },
    [[joinStep], []]
  );

  const steps = (
    hybrid ? [...arrangedSteps[0], ...arrangedSteps[1]] : arrangedSteps[0]
  ).map(convertStep);

  function convertStep(step: HowItWorksStep): StepProps {
    return {
      label: getLabel(hybrid ? step.label + step.labelExtension : step.label),
      description: getLabel(step.description),
      terms: step.terms,
      icon: step.icon,
      color: getColor(step.isBonusColor),
    };
  }

  function getLabel(label: string): string {
    const replacements: Record<string, number> = {
      '%sbkWagerAmount%': sbkWagerAmount,
      '%sbkReferrerRewardAmount%': sbkReferrerRewardAmount,
      '%sbkRefereeRewardAmount%': sbkRefereeRewardAmount,
      '%casinoWagerAmount%': casinoWagerAmount,
      '%casinoReferrerRewardAmount%': casinoReferrerRewardAmount,
      '%casinoRefereeRewardAmount%': casinoRefereeRewardAmount,
      '%offerWindowDays%': offerWindowDays || 30,
      '%referrerPercentage%': referrerPercentage,
    };

    return label.replace(
      /%sbkWagerAmount%|%sbkReferrerRewardAmount%|%sbkRefereeRewardAmount%|%casinoWagerAmount%|%casinoReferrerRewardAmount%|%casinoRefereeRewardAmount%|%offerWindowDays%|%referrerPercentage%/gi,
      (match) => replacements[match]?.toString() || ''
    );
  }

  function getColor(isBonusColor: boolean): string {
    return isBonusColor
      ? theme.colors.successTextLight
      : theme.colors.defaultText;
  }

  function getCasinoSteps(): CasinoSteps {
    const isMS: boolean = themeName === AppName.MOHEGAN_SUN_CASINO;
    return {
      casBonusStep: isMS ? msBonusStep : casBonusStep,
      casWagerStep: isMS ? msWagerStep : casWagerStep,
      casBonusAsymmetricStep: casBonusAsymmetricStep,
      casBonusZeroRecruitStep: casBonusZeroRecruitStep,
      casBonusSpinsStep: casBonusSpinsStep,
      casBonusSpinsZeroRecruitStep: casBonusSpinsZeroRecruitStep,
    };
  }

  function getProductSpecificSteps(): HowItWorksStep[] {
    const rewardSplitType = determineRewardSplitType(
      sbkReferrerRewardAmount,
      sbkRefereeRewardAmount,
      casinoReferrerRewardAmount,
      casinoRefereeRewardAmount,
      rewardType
    );

    const casSteps = getCasinoSteps();

    const baseSteps: HowItWorksStep[] = [sbkWagerStep, casSteps.casWagerStep];

    const typeSpecificSteps: RewardSpecificHowItWorksStep = {
      EXTRA_WALLET_REWARD: {
        symmetric: [sbkBonusStep, casSteps.casBonusStep],
        asymmetric: [sbkBonusAsymmetricStep, casSteps.casBonusAsymmetricStep],
        zeroRecruit: [
          sbkBonusZeroRecruitStep,
          casSteps.casBonusZeroRecruitStep,
        ],
      },
      BONUS_AWARDING_REWARD: {
        symmetric: [sbkBonusStep, casSteps.casBonusStep],
        asymmetric: [sbkBonusAsymmetricStep, casSteps.casBonusAsymmetricStep],
        zeroRecruit: [
          sbkBonusZeroRecruitStep,
          casSteps.casBonusZeroRecruitStep,
        ],
      },
      PRICE_BOOST_REWARD: {
        symmetric: [sbkProfitBoostTokenStep],
        zeroRecruit: [sbkProfitBoostTokenZeroRecruitStep],
      },
      BONUS_SPINS_REWARD: {
        symmetric: [casSteps.casBonusSpinsStep],
        zeroRecruit: [casSteps.casBonusSpinsZeroRecruitStep],
      },
    };

    return [
      ...baseSteps,
      ...(typeSpecificSteps?.[rewardType]?.[rewardSplitType] || []),
    ];
  }

  return (
    <Card data-testid="how-it-works">
      <CardHeader>
        <CardTitle>How it works</CardTitle>
      </CardHeader>

      {steps.map((step) => (
        <Step key={step.label} {...step} />
      ))}

      <TnCContainer>
        <Link
          href={tncUrl}
          children={<>Terms and conditions</>}
          target="_blank"
          fontSize={14}
        />
      </TnCContainer>
    </Card>
  );
};
