import { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Up, Down } from '../../assets/componentIcons';
import { CASINO_STATES, MS_STATE } from '../../constants';
import {
  Container,
  SelectedState,
  StateItem,
  StateList,
  StateMessage,
} from './StateSelectorStyles';
import { AppName } from 'Types';

type State = {
  name: string;
  abbreviation: string;
};

export type StateSelectorProps = {
  onChange: (stateAbbreviation: string) => void;
};

export const StateSelector = ({ onChange }: StateSelectorProps) => {
  const theme = useTheme();
  const isMoheganSun = theme.name === AppName.MOHEGAN_SUN_CASINO;

  const states: State[] = isMoheganSun
    ? [MS_STATE, ...CASINO_STATES]
    : CASINO_STATES;

  const [selectedState, setSelectedState] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleChange = (state: State) => {
    onChange(state.abbreviation);
    setSelectedState(state.name);
    toggle();
  };

  const stateSelectorRef = useRef<HTMLDivElement>(null);

  const closeWhenClickOutside = ({ target }: MouseEvent) => {
    if (
      stateSelectorRef.current &&
      isOpen &&
      !stateSelectorRef.current.contains(target as Node)
    ) {
      setIsOpen(false);
    }
  };

  document.addEventListener('mousedown', closeWhenClickOutside);

  return (
    <>
      <StateMessage>
        {'Casino Games are currently only available in the states below'}
      </StateMessage>
      <Container ref={stateSelectorRef}>
        <SelectedState
          onClick={toggle}
          isOpen={isOpen}
          data-testid="state-selector"
        >
          {selectedState || 'Please select your state'}
          <div>
            {isOpen ? (
              <Up color={theme.colors.stateSelectorArrow} />
            ) : (
              <Down color={theme.colors.stateSelectorArrow} />
            )}
          </div>
        </SelectedState>
        {isOpen && (
          <StateList>
            {states.map((state) => (
              <StateItem onClick={() => handleChange(state)} key={state.name}>
                {state.name}
              </StateItem>
            ))}
          </StateList>
        )}
      </Container>
    </>
  );
};
