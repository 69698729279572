import React from 'react';
import { Route, Routes } from 'react-router-dom';
import root from 'window-or-global';
import { Loading } from '../lib/components';
import { AppName } from '../lib/types';
import { ReferralCenterErrorPage } from '../lib/views/ErrorPage/ReferralCenterErrorPage';
import { ContextWrapper } from './contexts/AppContext';
import { datadogFunctions } from './utils/datadogService';
import { tagFunctions } from './utils/tagService';

tagFunctions();
datadogFunctions();

const App = () => {
  const themeName = new URLSearchParams(root.location.search).get('theme');

  const LandingContainer = React.lazy(
    () => import('./components/LandingContainer')
  );
  const ReferrerHomeContainer = React.lazy(
    () => import('./components/ReferrerHomeContainer')
  );

  const appName: AppName = PRODUCT as AppName;

  const loading = <ContextWrapper theme={appName} node={<Loading />} />;

  return (
    <React.Suspense fallback={loading}>
      <Routes>
        <Route path="/" element={<ReferrerHomeContainer />} />
        <Route path="/land/:code" element={<LandingContainer />} />
        <Route
          path="*"
          element={
            <ContextWrapper
              theme={themeName}
              node={<ReferralCenterErrorPage />}
            />
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default App;
