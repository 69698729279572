export enum EventType {
  LinkClick = 'LINK_CLICK',
}
interface ClickEvent {
  label: string;
  href?: string;
}

export interface LinkClickEvent extends ClickEvent {
  type: EventType.LinkClick;
}

export type Event = LinkClickEvent;
