import { ContextWrapper } from '../contexts/AppContext';
import { tagScreenLoad } from '../api/gtmAPI';
import { PageProps } from '../types/headerType';
import { ReferralCenterErrorPage } from '../../lib/views/ErrorPage/ReferralCenterErrorPage';
import { LandingErrorPage } from '../../lib/views/ErrorPage/LandingErrorPage';

export const LandingErrorPageContainer = (props: PageProps): JSX.Element => {
  tagScreenLoad(props.gtmProps);
  return (
    <ContextWrapper
      theme={props.theme}
      node={<LandingErrorPage header={props.header} message={props.message} />}
    />
  );
};

export const ReferralErrorPageContainer = (props: PageProps): JSX.Element => {
  tagScreenLoad(props.gtmProps);
  return (
    <ContextWrapper theme={props.theme} node={<ReferralCenterErrorPage />} />
  );
};
