import { ReactSVG } from 'react-svg';
import styled, { useTheme } from 'styled-components';

const StyledHead = styled.div`
  width: 100%;
  padding: 13px;
  background: ${({ theme }) => theme.colors.headerLogoBg};
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                justify-content: start;
                padding-left: 16px;
            }`
        }
    `}
`;

const HeaderImageDiv = styled.div`
  max-width: 136px;
  max-height: 39px;
  margin: 0 auto;
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                margin-left: 0; 
            }`
        }
    `}
`;

export const Header = () => {
  const theme = useTheme();
  return (
    <StyledHead>
      <HeaderImageDiv>
        <ReactSVG src={theme.icons.logo} />
      </HeaderImageDiv>
    </StyledHead>
  );
};
