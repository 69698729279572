type IconProps = {
  color?: string;
};

const Down = ({ color = '#1381E0' }: IconProps) => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.852.646a.5.5 0 0 0-.707 0L4.5 4.292.853.646a.5.5 0 0 0-.707.707l4 4a.498.498 0 0 0 .708 0l4-4a.502.502 0 0 0-.002-.707Z"
      fill={color}
    />
  </svg>
);

export default Down;
