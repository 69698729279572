import { getCookieValues } from './getCookieValues';
import { isAndroid, isDesktop } from 'react-device-detect';

const { webview } = getCookieValues();

export function gtmSiteVersion(desktop: boolean, webview: boolean) {
  if (desktop) return 'desktop';
  return webview ? 'native' : 'mobile';
}

export function amplitudeSiteVersion(desktop: boolean, webview: boolean) {
  if (desktop) return 'desktop';
  return webview ? 'native' : 'mobile_web';
}

export function amplitudeSitePlatform(
  desktop: boolean,
  webview: boolean,
  android: boolean
) {
  if (desktop) return 'desktop';
  if (!webview) return 'mobile_web';
  return android ? 'android' : 'ios';
}

export const getGtmSiteVersion = gtmSiteVersion(isDesktop, webview);
export const getAmplitudeSiteVersion = amplitudeSiteVersion(isDesktop, webview);
export const getAmplitudeSitePlatform = amplitudeSitePlatform(
  isDesktop,
  webview,
  isAndroid
);
