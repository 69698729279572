type IconProps = {
  color: string;
};

const TickCircle = (props: IconProps) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    name="Tick"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.5C0 3.806 3.806 0 8.5 0C13.194 0 17 3.806 17 8.5C17 13.194 13.194 17 8.5 17C3.806 17 0 13.194 0 8.5ZM7.467 10.76L12.116 5.18C12.293 4.968 12.607 4.939 12.82 5.116C13.032 5.293 13.061 5.609 12.884 5.821L7.884 11.821C7.793 11.929 7.663 11.994 7.522 12H7.5C7.368 12 7.24 11.948 7.147 11.854L4.147 8.854C3.952 8.659 3.952 8.342 4.147 8.147C4.342 7.952 4.659 7.952 4.854 8.147L7.467 10.76Z"
      fill={props.color}
    />
  </svg>
);

export default TickCircle;
