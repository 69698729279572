import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderState } from '../types/headerType';

export const initialState: HeaderState = {
  token: '',
  state: '',
  ip: '',
  deviceId: '',
  username: '',
  loading: true,
  error: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<HeaderState>) => {
      state.token = action.payload.token;
      state.state = action.payload.state.toUpperCase();
      state.ip = action.payload.ip;
      state.deviceId = action.payload.deviceId;
      state.username = action.payload.username;
      state.loading = action.payload.loading;
      state.error = action.payload.error;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

export const { setHeader, setLoading, setError } = headerSlice.actions;

export default headerSlice.reducer;
