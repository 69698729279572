type IconProps = {
  stroke?: string;
  visibility?: string;
};

const Line = ({ stroke = '#445058', visibility = 'visible' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 55 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.5"
      y1="0.5"
      x2="54"
      y2="0.5"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      visibility={visibility}
    />
  </svg>
);

export default Line;
