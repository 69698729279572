import styled from 'styled-components';
import { useCopy } from '../../contexts/CopyContext';

const Box = styled.div`
  ${({ theme }) => `
        background: ${theme.colors.containerBg};
        border-top: 1px solid ${theme.colors.containerBorder};
    `}
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  margin-top: -1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Header = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.headerText};
  font-weight: Normal;
  padding-bottom: 5px;
`;

const Message = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.defaultText};
  font-weight: Normal;
  max-width: 345px;
  padding: 0px 5px;
`;

export const ReferralMessage = () => {
  const copy = useCopy();
  return (
    <Box>
      <Header>Don’t see a friend?</Header>
      <Message>
        {copy.referralMessage} Any referral a year or older will not be shown.
        Bonus amounts may change based on special offers or promotions.
      </Message>
    </Box>
  );
};
