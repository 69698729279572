import React, { createContext, ReactElement, useMemo } from 'react';

import { AppName } from '../types';

export interface AppState {
  appName: AppName | null;
  children: ReactElement;
}

interface AppCtxState {
  memoizedAppName: AppName | null;
}

const appCtxDefaultValue: AppCtxState = {
  memoizedAppName: null,
};

export const AppStateContext = createContext(appCtxDefaultValue);

export const AppContextProvider: React.FC<AppState> = ({
  appName,
  children,
}) => {
  const memoizedAppName = useMemo(() => {
    if (!appName) return null;
    return appName;
  }, [appName]);

  return (
    <AppStateContext.Provider
      value={{
        memoizedAppName,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
