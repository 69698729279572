import styled from 'styled-components';
import { fanduelGreyD4 } from '../../themes/colors';

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: auto;
  padding-bottom: 16px;
`;

export const Icon = styled.div<{ gridColumn: number }>`
  svg {
    width: 42px;
    height: 42px;
    display: inline-block;
    vertical-align: middle;
    .dollar-fill {
      fill: ${({ theme }) => theme.colors.successIconLight};
    }
    .dollar-stroke {
      stroke: ${({ theme }) => theme.colors.successIconLight};
    }
    .icon-fill {
      fill: ${fanduelGreyD4};
    }
    .icon-stroke {
      stroke: ${fanduelGreyD4};
    }
  }
  grid-column: ${(props) => props.gridColumn};
  align-self: center;
`;

export const TextWrapper = styled.div<{ gridColumn: string; color: string }>`
  font-weight: 700;
  font-size: 12px;
  grid-row: 2;
  grid-column: ${(props) => props.gridColumn};
  white-space: pre-wrap;
  color: ${(props) => props.color};
  padding-top: 5px;
`;

export const BonusTextWrapper = styled(TextWrapper)`
  margin-left: -10px;
`;

export const LineWrapper = styled.div<{ gridColumn: number }>`
  grid-column: ${(props) => props.gridColumn};
  align-self: center;
  width: 55px;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`;
