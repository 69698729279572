import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_21 from '../../assets/icons/common/RG_21.svg';
import { Space } from './Footer';
import { Container, Text, Line } from './ResponsibleGamingStyles';

export const ResponsibleGamingSportsbook = () => (
  <Container>
    <ReactSVG src={rg_21} />
    <Text>
      <Line>Gambling Problem? Call 1-800-GAMBLER or</Line>
      <Line>
        visit
        <Space>
          <Link
            href="https://www.fanduel.com/rg"
            target="_blank"
            children={<>FanDuel.com/RG</>}
            fontSize={14}
          />
        </Space>
        (CO, D.C., IA, IL, KS (in affiliation with Kansas
      </Line>
      <Line>Star Casino), KY, LA, MI, NC, NJ, OH, PA, TN, VA, VT, WY),</Line>
      <Line>1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ),</Line>
      <Line>
        1-888-789-7777 or visit
        <Space>
          <Link
            href="https://ccpg.org/chat/"
            target="_blank"
            children={<>ccpg.org/chat</>}
            fontSize={14}
          />
        </Space>
        (CT), 1-800-9-WITH-IT (IN),
      </Line>
      <Line>
        visit
        <Space>
          <Link
            href="https://www.mdgamblinghelp.org"
            target="_blank"
            children={<>www.mdgamblinghelp.org</>}
            fontSize={14}
          />
        </Space>
        (MD),
      </Line>
      <Line>1-877-8-HOPENY or text HOPENY (467369) (NY),</Line>
      <Line>
        or visit
        <Space>
          <Link
            href="https://www.1800gambler.net/"
            target="_blank"
            children={<>www.1800gambler.net</>}
            fontSize={14}
          />
        </Space>
        (WV).
      </Line>
    </Text>
  </Container>
);
