import React from 'react';
import { OriginatingProduct } from '../../types/OfferModel';
import { ResponsibleGamingCasino } from './ResponsibleGamingCasino';
import { ResponsibleGamingSportsbook } from './ResponsibleGamingSportsbook';
import { Country } from '../../../app/utils/getCountry';
import { ResponsibleGamingCanada } from './ResponsibleGamingCanada';

export type FooterProps = { product: OriginatingProduct; country: Country };

export const Footer = ({ product, country }: FooterProps) => {
  if (country === 'canada') return <ResponsibleGamingCanada />;
  return product === 'CASINO' ? (
    <ResponsibleGamingCasino />
  ) : (
    <ResponsibleGamingSportsbook />
  );
};

export const Space = ({ children }: { children: React.ReactElement }) => (
  <> {children} </>
);
