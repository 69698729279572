const errorPageContent = {
  headerText: 'Something went wrong',
  message: "We're looking into it. Try again later",
  buttonText: 'Visit Mohegan Sun Casino',
};

export const mohegansunText = {
  maxHeader: "You're on a hot streak!",
  errorPage: {
    referralCenter: errorPageContent,
    landingPage: errorPageContent,
  },
};
