import Cookies from 'js-cookie';
import { MS_STATE } from '../constants';

export const setRedirectCookie = (state?: string, isMoheganSun?: boolean) => {
  const fdCasinoHomeUrl = process.env.FD_CASINO_HOME_URL || '';
  const homeURL = process.env.HOME_URL || '';

  const externalReferrerUrl =
    isMoheganSun && state !== MS_STATE.abbreviation ? fdCasinoHomeUrl : homeURL;
  // Product domain with a leading .
  const cookieDomain =
    NODE_ENV !== 'local'
      ? externalReferrerUrl.replace(/^https?:\/\//, '.')
      : '';

  Cookies.remove('referrer-redirect');
  Cookies.set('referrer-redirect', externalReferrerUrl, {
    domain: cookieDomain,
    // One hour from now
    expires: 1 / 24,
  });
};
