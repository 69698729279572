import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.h1`
  ${({ theme }) => `
        color: ${theme.colors.headerText};
        font-weight: ${theme.header.text.weight};
        font-size: ${theme.header.text.size};
        margin: ${theme.margins.headerSection};
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                display: none;
                margin-top: 15px;
            }`
        }
    `}
`;

export type HeaderProps = {
  text: string;
};

export const HeaderText = (props: HeaderProps) => {
  return <StyledHeader>{props.text}</StyledHeader>;
};
