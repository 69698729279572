import moment, { Moment } from 'moment';
import { useTheme } from 'styled-components';
import { Referral, ReferredUser } from '../../types/ReferralModel';
import { ProgressBar } from './ProgressBar';
import { Box, Container, Left, Message, Name, Right } from './ProgressStyle';

type Props = {
  referredUser: ReferredUser;
};

type StatusProps = {
  referral: Referral;
  daysLeft: number;
  timeLeft: string;
  promotionExpDate: Moment;
  productPrefix: boolean;
};

const statusText = (
  text: string,
  { progress, productType }: Referral,
  productPrefix: boolean,
  color: string
) => {
  let fullPrefix = '';

  if (productPrefix) {
    fullPrefix += `${productType} `;

    if (!['BONUS', 'BONUS_FRIEND'].includes(progress)) {
      fullPrefix += '- ';
    }
  }

  return <span style={{ color: color }}>{fullPrefix + text}</span>;
};

const Status = ({
  referral,
  daysLeft,
  timeLeft,
  promotionExpDate,
  productPrefix,
}: StatusProps) => {
  const theme = useTheme();

  switch (referral.progress) {
    case 'NOT_JOINED':
      return statusText(
        'NOT JOINED',
        referral,
        productPrefix,
        theme.colors.defaultText
      );
    case 'JOINED':
      return daysLeft > 5
        ? statusText(
            `${timeLeft} LEFT`,
            referral,
            productPrefix,
            theme.colors.defaultText
          )
        : statusText(
            `${timeLeft} LEFT`,
            referral,
            productPrefix,
            theme.colors.warningText
          );
    case 'WAGERED':
      return statusText(
        'PROCESSING',
        referral,
        productPrefix,
        theme.colors.defaultText
      );
    case 'BONUS':
      return statusText(
        'BONUS EARNED',
        referral,
        productPrefix,
        theme.colors.successTextDark
      );
    case 'BONUS_FRIEND':
      return statusText(
        'BONUS FOR FRIEND',
        referral,
        productPrefix,
        theme.colors.successTextDark
      );
    case 'EXPIRED':
      return statusText(
        `EXPIRED ` + promotionExpDate.format('ll').toUpperCase(),
        referral,
        productPrefix,
        theme.colors.progressText
      );
    case 'INELIGIBLE':
      return statusText(
        'INELIGIBLE',
        referral,
        productPrefix,
        theme.colors.warningText
      );
  }
};

const message = ({ referrals }: ReferredUser) => {
  if (!referrals) {
    throw new Error('No referrals for user');
  }

  // In a hybrid offer the expiry dates will be the same
  const promotionExpDate: Moment = moment(referrals[0].expiryDate || '');

  const daysLeft: number = promotionExpDate.diff(moment(), 'days');

  // Human readable https://momentjs.com/docs/#/displaying/tonow/
  const timeLeft: string = promotionExpDate.toNow(true).toUpperCase();

  if (
    referrals.length === 2 &&
    referrals[0].progress !== referrals[1].progress
  ) {
    // Hybrid different state -> show two statuses
    return (
      <>
        {referrals.map((r) => (
          <Status
            key={r.referralId}
            referral={r}
            daysLeft={daysLeft}
            timeLeft={timeLeft}
            promotionExpDate={promotionExpDate}
            productPrefix={true}
          />
        ))}
      </>
    );
  } else {
    return (
      <Status
        referral={referrals[0]}
        daysLeft={daysLeft}
        timeLeft={timeLeft}
        promotionExpDate={promotionExpDate}
        productPrefix={false}
      />
    );
  }
};

const invalid = (referrals: Referral[]) => {
  return referrals.every((r) => ['INELIGIBLE', 'EXPIRED'].includes(r.progress));
};

export const Progress = (props: Props) => {
  const hybrid =
    props.referredUser?.referrals?.length == 2 &&
    props.referredUser.referrals[0].productType !==
      props.referredUser.referrals[1].productType;

  return (
    <Container>
      <Box>
        <Left>
          <Name greyedOut={invalid(props.referredUser.referrals)}>
            {props.referredUser.username}
          </Name>
          <Message>{message(props.referredUser)}</Message>
        </Left>
        <Right>
          {props.referredUser.referrals.map((r) => (
            <div key={r.productType}>
              <ProgressBar referral={r} hybrid={hybrid} />
            </div>
          ))}
        </Right>
      </Box>
    </Container>
  );
};
