import styled from 'styled-components';

export const Home = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100vw;
  overflow-x: ${({ theme }) => theme.overflow.imageDiv.isHidden};
`;

export const Container = styled.div`
  width: 640px;
  margin: 0 auto 15px auto;
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                width: 100%;
            }`
        }
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.paddings.column.large};
  margin-bottom: ${({ theme }) => theme.margins.topSection.row.medium};
  ${({ theme }) => `
        ${
          theme.mediaQueries.small.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.small.maxWidth}) {
                margin: ${theme.margins.topSection.rowSmall.margin};
                padding: ${theme.paddings.column.small};
            }`
        }
    `}
`;

export const Column = styled.div`
  margin: ${({ theme }) => theme.margins.topSection.column.margin};
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                margin: ${theme.margins.topSection.column.margin};
            }`
        }
        ${
          theme.mediaQueries.small.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.small.maxWidth}) {
                margin-right: -60px;
            }`
        }
    `}
`;

export const ImageDiv = styled.div`
  max-width: ${({ theme }) => theme.margins.topSection.imageDiv.maxWidth};
  max-height: ${({ theme }) => theme.margins.topSection.imageDiv.maxHeight};
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                margin: ${theme.margins.topSection.imageDiv.medium.margin};
                scale: ${theme.scale.medium};
                position: relative;
                top: ${theme.position};
            }`
        }
        ${
          theme.mediaQueries.small.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.small.maxWidth}) {
                margin: ${theme.margins.topSection.imageDiv.small.margin};
                scale: ${theme.scale.small};
                position: relative;
                top: ${theme.position};
            }`
        }
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                margin: ${theme.margins.topSection.imageDiv.extraSmall.margin};
                scale: ${theme.scale.extraSmall};
            }`
        }
    `};
  &.bonusSpins {
    ${({ theme }) => `
    ${
      theme.mediaQueries.medium.maxWidth &&
      `@media screen and (max-width: ${theme.mediaQueries.medium.maxWidth}) {
            margin: 0px -20px 0px 0px;
            scale: 0.9;
            position: relative;
            top: 0;
        }`
    }
        ${
          theme.mediaQueries.regular.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.regular.maxWidth}) {
                margin: 0px -25px 0px 0px;
                scale: 0.8;
                position: relative;
                top: -20px;
            }`
        }
        ${
          theme.mediaQueries.small.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.small.maxWidth}) {
                margin: 0 -55px 0 0;
                scale: 0.6;
                top: -30px;
                
            }`
        }
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media screen and (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                margin: 0px -60px 0 0;
                scale: 0.5;
                position: relative;
                top: -40px;
            }`
        }
    `};
  }
`;

export const TopMargin = styled.div`
  margin-top: ${(props: { value: string }) => props.value};
`;

export const TopMarginText = styled(TopMargin)`
  margin-right: -20px;
`;

export const HorizontalPadding = styled.div`
  padding-left: 0px;
  padding-right: 0px;
  background: ${({ theme }) => theme.colors.topSectionThemeBg};
  border-radius: ${({ theme }) => theme.borderRadius.horizontalPadding.radius};
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                width: 100%;
                padding: ${theme.paddings.horizontalPadding.large};
                border-radius: 0;
            }`
        }
    `}
`;
