import { DefaultTheme } from 'styled-components';

import { AppName } from '../types';
import { sportsbook } from '../themes/sportsbook';
import { casino } from '../themes/casino';
import { mohegansun } from '../themes/mohegansun';

export const getTheme = (
  appName: AppName = AppName.SPORTSBOOK
): DefaultTheme => {
  const themes = {
    [AppName.SPORTSBOOK]: sportsbook,
    [AppName.SPORTSBOOK_DARK]: sportsbook,
    [AppName.CASINO]: casino,
    [AppName.MOHEGAN_SUN_CASINO]: mohegansun,
  };
  return themes[appName];
};
