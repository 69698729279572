import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 16px;
`;

export const Box = styled.div`
  display: flex;
  padding: 16px 0px;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.containerBorder};
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  overflow: hidden;
`;

export const Name = styled.span<{ greyedOut: boolean }>`
  font-size: 16px;
  color: ${(props) =>
    props.greyedOut
      ? props.theme.colors.progressText
      : props.theme.colors.defaultText};
  font-weight: Bold;
  padding-bottom: 4px;
  padding-top: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Message = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  font-weight: Normal;
  font-family: ProximaNovaCondensed;
  letter-spacing: 1px;
  gap: 5px;
  > * {
    &:nth-child(2) {
      padding-bottom: 22px;
    }
  }
  ${({ theme }) => `
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                > * {
                    &:nth-child(2) {
                       padding-bottom: 20px;
                    }
                };
                letter-spacing: normal;
                gap: 0px;
            }`
        }
    `}
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  align-items: center;
  flex: 1;
  padding-right: 15px;
  letter-spacing: 1px;
  ${({ theme }) => `
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                padding-right: 26px;
            }`
        }
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                letter-spacing: normal;
            }`
        }
    `}
`;
