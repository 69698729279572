import styled from 'styled-components';

export const StateMessage = styled.p`
  padding: 7px 30px 14px;
  font-size: 14px;
  text-align: center;
`;

export const Container = styled.div`
  position: relative;
  margin-bottom: 17px;
  height: 44px;
`;

export const SelectedState = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
  ${({ theme }) => `
        font-weight: ${theme.defaultText.text.boldText};
        font-size: ${theme.defaultText.text.size};
        border-radius: ${theme.borderRadius.horizontalPadding.radius};
        border: 2px solid ${theme.colors.stateSelectorDetail};
        background: ${theme.colors.contentBg};
    `}
  ${(props) =>
    props.isOpen &&
    `
            border-bottom-right-radius 0px;
            border-bottom-left-radius: 0px;
            border-bottom: 0px;
        `}
`;

export const StateList = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  ${({ theme }) => `
        font-size: ${theme.defaultText.text.size};
        border: 2px solid ${theme.colors.stateSelectorDetail};
        border-radius: 0px 0px
            ${theme.borderRadius.horizontalPadding.radius}
            ${theme.borderRadius.horizontalPadding.radius};
        background: ${theme.colors.contentBg};
    `}
`;

export const StateItem = styled.li`
  display: flex;
  padding: 12px 24px;
  list-style: none;
  ${({ theme }) => `
        &:hover,
        :focus,
        :focus:hover {
            background-color: ${theme.colors.stateSelectorDetail};
            outline: none;
        }
        border-bottom: 1px solid ${theme.colors.stateSelectorDetail};
    `}
  &:last-child {
    border-bottom: 0px;
  }
`;
