import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import root from 'window-or-global';
import * as amplitude from '@fanduel/core-analytics';
import { tagIt } from '../../utils/gtmService';
import copyIcon from '../../assets/icons/common/Copy.svg';

const Outer = styled.div`
  display: flex;
  border-radius: 4px;
  height: 87px;
  padding: 16px;
  ${({ theme }) => `
        border: solid 1px ${theme.colors.containerBorder};
        background-color: ${theme.colors.contentBg};
        margin: ${theme.margins.topSection.outer.margin};
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                height: 120px;
            }`
        }
        ${
          theme.mediaQueries.medium.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                margin: ${theme.margins.topSection.outer.medium};
            }`
        }
    `}
`;

const Inner = styled.div`
  display: flex;
  flex: 1;
  border: solid 1px ${({ theme }) => theme.colors.inviteBorder};
  border-radius: 4px;
  align-items: stretch;
  padding: 16px;
  justify-content: space-between;
  &:hover {
    background-color: ${({ theme }) => theme.colors.containerBg};
    cursor: pointer;
  }

  ${({ theme }) => `
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                flex-direction: column;
                align-items: center;
            }`
        }
    `}
`;

const CopyDiv = styled.div`
  color: ${({ theme }) => theme.colors.linkText};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
`;

const CopyText = styled.div`
  padding-left: 10px;
  width: 70px;
`;

const SVGWrapper = styled(ReactSVG)`
  padding-top: 2px;
`;

type Props = {
  previewText: string;
  link: string;
};

export const WebInvite = (props: Props) => {
  const [text, setText] = useState('Copy');

  const copyWeb = async () => {
    tagIt('referral_copy_link_clicked', text, 'referral_share_links');
    amplitude.log('RAF Share Link Copied', { Module: 'raf_hub' });
    setText('Copied');
    await root.navigator.clipboard.writeText(
      props.previewText + ' ' + props.link
    );
  };

  return (
    <Outer data-testid="invite-link-box">
      <Inner onClick={copyWeb} data-testid="invite-box">
        <div>{props.link}</div>
        <CopyDiv data-testid="copy-button">
          <SVGWrapper src={copyIcon} />
          <CopyText>{text}</CopyText>
        </CopyDiv>
      </Inner>
    </Outer>
  );
};
