import styled, { useTheme } from 'styled-components';

const Container = styled.div`
  color: ${({ theme }) => theme.colors.topSectionText};
  text-align: center;
  padding-bottom: 8px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 14px;
`;

const SmallPrint = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.topSectionText};
  text-align: center;
`;

const MaxReferralsWrapper = styled.div`
  margin-top: -10px;
  position: ${({ theme }) => theme.positionType};
  top: ${({ theme }) => theme.positionTop};
`;

export const MaxReferralsReached = () => {
  const theme = useTheme();
  return (
    <MaxReferralsWrapper>
      <Container>
        <Header>{theme.text.maxHeader}</Header>
        <Description>
          You've earned the max bonus after referring 5 people.
        </Description>
      </Container>

      <SmallPrint>
        <span style={{ fontWeight: 'Bold' }}>Note:</span> You can refer as many
        friends as you want, but you can only earn 5 bonuses in 30 days. <br />{' '}
        See Terms and Conditions for details.
      </SmallPrint>
    </MaxReferralsWrapper>
  );
};
