import { ReactSVG } from 'react-svg';
import { useTheme } from 'styled-components';

import { AppName, OfferType, RewardType } from 'Types';
import { useCopy } from 'Contexts';
import {
  BetSlip,
  Boost,
  CardsCircle,
  DollarCircle,
  Player,
  CasBonusSpins,
} from '../../assets/icons/common';
import { Line } from '../../assets/componentIcons';

import {
  BonusTextWrapper,
  Icon,
  LineWrapper,
  Row,
  TextWrapper,
} from './StepsStyles';

type StepRowProps = {
  joinText: string;
  middleIcon: string;
  rewardText: string;
  rewardType?: string;
  wagerText: string;
  isZeroRecruitSportsbook?: boolean;
};

type StepsProps = {
  offerType: OfferType;
  rewardType: RewardType;
  isZeroRecruitSportsbook: boolean;
};

const renderIcon = (icon: string, column: number) => {
  return (
    <Icon gridColumn={column}>
      <ReactSVG src={icon} />
    </Icon>
  );
};

export const StepRow = ({
  joinText,
  middleIcon,
  rewardText,
  rewardType,
  wagerText,
  isZeroRecruitSportsbook,
}: StepRowProps) => {
  const theme = useTheme();

  const rewardIcons: { [key: string]: React.ReactNode } = {
    PRICE_BOOST_REWARD: Boost,
    BONUS_SPINS_REWARD: CasBonusSpins,
  };

  const finalIcon = isZeroRecruitSportsbook
    ? DollarCircle
    : rewardIcons[rewardType as RewardType] || DollarCircle;

  return (
    <Row>
      {renderIcon(Player, 3)}
      <TextWrapper gridColumn={'2 / 5'} color={theme.colors.defaultText}>
        {joinText}
      </TextWrapper>
      <LineWrapper gridColumn={5}>
        <Line />
      </LineWrapper>
      {renderIcon(middleIcon, 7)}
      <TextWrapper gridColumn={'5 / 10'} color={theme.colors.defaultText}>
        {wagerText}
      </TextWrapper>
      <LineWrapper gridColumn={9}>
        <Line />
      </LineWrapper>
      {renderIcon(finalIcon, 11)}
      <BonusTextWrapper
        gridColumn={'10 / 14'}
        color={theme.colors.successIconLight}
      >
        {rewardText}
      </BonusTextWrapper>
    </Row>
  );
};

export const Steps = ({
  offerType,
  rewardType,
  isZeroRecruitSportsbook,
}: StepsProps) => {
  const theme = useTheme();
  const copy = useCopy();

  const isHybrid = offerType === 'HYBRID';

  const sportsbookRow = (
    <StepRow
      joinText={copy.landingHowItWorksSbkStep.join}
      wagerText={copy.landingHowItWorksSbkStep.wager}
      rewardText={copy.landingHowItWorksSbkStep.reward}
      middleIcon={BetSlip}
      {...{ rewardType }}
      isZeroRecruitSportsbook={isZeroRecruitSportsbook}
    />
  );
  const casinoRow = (
    <StepRow
      joinText={copy.landingHowItWorksCasStep.join}
      wagerText={copy.landingHowItWorksCasStep.wager}
      rewardText={copy.landingHowItWorksCasStep.reward}
      middleIcon={CardsCircle}
      {...{ rewardType }}
    />
  );

  return (
    <>
      {theme.name === AppName.CASINO ||
      theme.name === AppName.MOHEGAN_SUN_CASINO ? (
        <>
          {casinoRow}
          {isHybrid && sportsbookRow}
        </>
      ) : (
        <>
          {sportsbookRow}
          {isHybrid && casinoRow}
        </>
      )}
    </>
  );
};
